import React, {useState, useContext, useEffect, useRef} from 'react';
import {
    Box,
    Center,
    Text,
    Input,
    InputGroup,
    InputRightElement,
    Icon,
    Heading,
    Image,
    FormControl,
    Button,
    FormErrorMessage,
    useToast,
    Spinner,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton, ModalBody, HStack, UnorderedList, ListItem, Flex, Wrap, createIcon, VStack
} from '@chakra-ui/react'
import {Field, Formik, useFormik, useFormikContext} from "formik";
import { ChevronRightIcon } from '@chakra-ui/icons'
import StartRoundedIcon from '@mui/icons-material/StartRounded';
// import {BsArrowReturnLeft} from "react-icons/bs";
import {useSessionContext} from "supertokens-auth-react/recipe/session";
import {UserContext} from "../UserContext";
// import {useFormik} from "formik";
// import NewReminder from "./NewReminder";
// import BetaLoginPrompt from "./BetaLoginPrompt";
// import {Navigate, useNavigate} from "react-router-dom";
// import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
// import Face3OutlinedIcon from '@mui/icons-material/Face3Outlined';
// import { Logtail } from "@logtail/browser";

function ReminderInputBox({ value, onValueChange, mode}) {
    // const inputRef = useRef(null);
    // const user = useContext(UserContext);
    // let user_details = user.user_details
    // let setNeedsRefresh = user.setNeedsRefresh
    // let [loading, setLoading] = useState(false)
    // let [newReminder, setNewReminder] = useState({})
    // let [newReminderPopNeeded, setNewReminderPopNeeded] = useState(false)
    // let [addChannelPopNeeded, setAddChannelPopNeeded] = useState(false)
    // let [logInPromptNeeded, setLogInPromptNeeded] = useState(false)
    // let session = useSessionContext();
    // let {doesSessionExist, userId, accessTokenPayload} = session;
    // const toast = useToast()
    const formik = useFormikContext();

    const handleSuggestionClick = (suggestion) => {
        console.log('suggestion clicked: ', suggestion);
        // setReminderInput(suggestion);
        formik.setFieldValue('reminderInput', suggestion)
    };

    return (
        <Box>
            <form onSubmit={formik.handleSubmit}>
                <FormControl isInvalid={formik.errors.reminderInput && formik.touched.reminderInput}>
                    <InputGroup>
                    <Input
                        name="reminderInput"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.reminderInput}
                        // value={value}
                        // onChange={(e) => onValueChange(e.target.value)}
                        id="reminderInput"
                        placeholder="What do you need to remember?"
                        autoFocus
                        // isDisabled={loading}
                        isDisabled={formik.isSubmitting}
                        // borderRadius={{base: "full", sm: "full", md: "full", lg: "full", xl: "full", "2xl": "full"}}
                        borderRadius={'full'}
                        border={'none'}
                        pr={'50px'}
                        bg={'white'}
                        // size={{lg: "lg", xl: "lg", "2xl": "lg"}}
                        size={'lg'}
                        sx={{
                            boxShadow: 'xl',
                            _focus: {
                                boxShadow: '0 0 40px 15px #FDE376',
                            },
                            _hover: {
                                boxShadow: '0 0 40px 15px #FDE376',
                            }

                        }}
                    />
                    <InputRightElement
                        bg={'brand.green'}
                        rounded={'full'}
                        // w={{lg: "10px", xl: "md", "2xl": "md"}}
                        transform={"translate(-10%, 10%)"}
                        ml={2}
                        children={
                            <Button type={'submit'}
                                bg={'brand.green'}
                                
                                _hover={{ bg: 'brand.green_hover' }}
                                rounded={'full'}
                            ><Icon 
                                boxSize={{lg: 9, xl: 9, "2xl": 9}} as={ChevronRightIcon}          
                                color={'white'}
                            /></Button>
                        }
                    />
                    </InputGroup>
                    {formik.errors.reminderInput && formik.touched.reminderInput && (
                        <FormErrorMessage>{formik.errors.reminderInput}</FormErrorMessage>
                    )}
                </FormControl>
            </form>
            {mode === "demo" && (
                <Box pt={1} id='demo-button-box' w={'100%'}>
                    <Center>
                        <VStack w={'100%'}>
                            <Flex direction={'row'} wrap="wrap" justifyContent={'flex-start'} gap={"2px"}>
                                <Heading size="" p={0} color={'blackAlpha.700'}>
                                    <Box pr={1} as={'span'}>
                                        <StartRoundedIcon style={{
                                            // paddingRight: 2,
                                            fontSize: 12,
                                            color: 'blackAlpha.700',
                                            transform: 'translateY(0.1rem)',
                                            
                                        }}/>
                                    </Box>
                                    <Box as="span" fontSize={12}>
                                        Try asking
                                    </Box>
                                    
                                    {/* <FileUploadRoundedIcon style={{
                                        fontSize: 20,
                                        color: 'blackAlpha.700',
                                        transform: 'translateY(0.1rem)',
                                        
                                    }}/> */}
                                </Heading>
                                <Button
                                    bg={'whiteAlpha.600'} 
                                    variant="outline" 
                                    size={'xs'} 
                                    onClick={() => handleSuggestionClick('Remind Tommy to take out the trash tonight')}
                                >
                                    <Text fontSize={12} color={'blackAlpha.700'} pt={0}>
                                        Remind Tommy to take out the trash tonight
                                    </Text>
                                </Button>
                                <Button 
                                    bg={'whiteAlpha.600'} 
                                    variant="outline" 
                                    size={'xs'} 
                                    onClick={() => handleSuggestionClick('Remind me to take my medicine at 8 AM')}
                                >
                                    <Text fontSize={12} color={'blackAlpha.700'} pt={0}>
                                        Remind me to take my medicine at 8 AM
                                    </Text>
                                </Button>
                                <Button
                                    bg={'whiteAlpha.600'}  
                                    variant="outline" 
                                    size={'xs'} 
                                    onClick={() => handleSuggestionClick('Turn on the crockpot tomorrow morning')}
                                >
                                    <Text fontSize={12} color={'blackAlpha.700'} pt={0}>
                                        Turn on the crockpot tomorrow morning
                                    </Text>
                                </Button>
                            </Flex>
                            {/* <Flex direction={'row'} justifyContent={'center'} gap={0}>
                                <Button
                                    bg={'whiteAlpha.600'}  
                                    variant="outline" 
                                    size={'xs'} 
                                    onClick={() => handleSuggestionClick('Turn on the crockpot tomorrow morning')}
                                >
                                    <Text fontSize={12} color={'blackAlpha.700'} pt={0}>
                                        Turn on the crockpot tomorrow morning
                                    </Text>
                                </Button>
                            </Flex> */}
                        </VStack>
                    </Center>
                </Box>
            )}
            
        </Box>
    );   
    
}

export default ReminderInputBox;
import React from 'react';
import { Box, Text, VStack, useColorModeValue, Avatar, Heading, HStack, Spacer } from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';

const TestimonialBox = ({ name, role, testimonial, avatarImg }) => {
  const bg = useColorModeValue('white', 'gray.800');
  // const borderColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <VStack
      spacing={4}
      bg={bg}
      boxShadow="lg"
      padding={4}
      borderRadius="lg"
      // borderWidth="1px"
      // borderColor={borderColor}
      // width={{lg: "xs", xl: "sm", "2xl": "md"}}
      align="start"
      h={"250px"}
    >
      <Box display="flex" alignItems="center">
        {Array(5)
          .fill('')
          .map((_, i) => (
            <StarIcon key={i} color={i < 5 ? "yellow.400" : "gray.300"} />
          ))}
      </Box>
      <Text fontSize="md" fontStyle="italic">
        "{testimonial}"
      </Text>
      <Spacer />
      <HStack w={"100%"} pr={5} pb={3}>
        <VStack spacing={1} align="start">
          <Heading size="sm">{name}</Heading>
          <Text fontSize="sm" color="gray.500">{role}</Text>
        </VStack>
        <Spacer />
        <Avatar name={name} src={avatarImg}/>
      </HStack>
      
    </VStack>
  );
};

export default TestimonialBox;

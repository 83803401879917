import { useParams, Navigate, useNavigate } from "react-router-dom";
import React, {useContext, useState, useEffect} from 'react';
import {doesSessionExist, useSessionContext} from "supertokens-auth-react/recipe/session";
import {UserContext} from "../UserContext";
import {Box, Button, Center, Heading, Text, useToast} from "@chakra-ui/react";
import { NicknameInput } from "./TxRxReminders";
import { Logtail } from "@logtail/browser";
import TopBar from "./TopBar";

// /cx/:cx_id/:action?
function Connection(props) {
    const routeParams = useParams();
    const user = useContext(UserContext)
    let [isLoading, setIsLoading] = useState(true);
    let [updateIsLoading, setUpdateIsLoading] = useState(false);
    let [cxResponse, setCxResponse] = useState({});
    const navigate = useNavigate();
    let [newUserFormState, setNewUserFormState] = useState({nickname: []})
    const toast = useToast()
    const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN)
    // console.log(routeParams);
    // console.log(user)

    // const url = process.env.REACT_APP_API_DOMAIN + "/cx"
    useEffect(() => {
        const url = process.env.REACT_APP_API_DOMAIN + "/cx"
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                cx_id: routeParams.cx_id,
                action: routeParams.action,
                // user_id: user.user_id
            }),
        }
        // console.log(options)
        try {
            fetch(url, options)
                .then(response => response.json())
                .then(response => {
                    console.log(response)
                    setCxResponse(response)
                    setIsLoading(false)
                })
            // let resJson = res.json()
            // console.log(resJson)
        } catch (err) {
            logtail.error(user.user_details.email + "Error in Connection.js", err)
            logtail.flush()
            console.log(err)
        }
    }, []) //routeParams.cx_id, routeParams.action

    // console.log(cxResponse)

    let handleSubmit = async (e) => {
        e.preventDefault()
        setUpdateIsLoading(true)
        let newArr = newUserFormState.nickname.filter(function(e){return e})
        setNewUserFormState({...newUserFormState, nickname: newArr})
        let obj = {
            // supertokens_id: userId,
            target_email: cxResponse.sender_email,
            name: newUserFormState.nickname
        }
        // For some reason we have to do this twice.  I don't know.
        obj.name = obj.name.filter(function(e){return e})

        try {
            // console.log(obj)
            let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/nickname", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            })
                .then(res => res.json())
                .then(resJSON => {
                    // if (res.status === 200) {
                        // let resJSON = res.json()
                        // console.log(resJSON)
                        if (resJSON.results === "no connection found") {
                            toast({
                                title: "You don't have a connection with this user",
                                status: "info",
                                isClosable: true
                            })
                            setUpdateIsLoading(false)
                        } else {
                            toast({
                                title: "Success!",
                                description: resJSON.results,
                                status: "success",
                                isClosable: true
                            })
                            setUpdateIsLoading(false)
                            user.setNeedsRefresh(true)
                        }
                })

        } catch (err){
            console.log(err)
            logtail.error(user.user_details.email + "Error in Connection.js", err)
            logtail.flush()
            toast({
                title: "Error adding data",
                status: "error",
                isClosable: true
            })
            setUpdateIsLoading(false)
        }



    }

    if (isLoading) {
        return (
            <>
                <TopBar />
                <div>Loading...</div>
            </>
        )
    }
    else if (cxResponse.results === "invalid user connection") {
        return <Navigate to={'/'} />
    }
    else if (cxResponse.user === 'receiver') {
        if (cxResponse.action_needed) {
            if (cxResponse.results === 'pending') {
                return (
                    <div>
                        <TopBar />
                        <Text>
                            {cxResponse.sender_email} would like to send you notifications. Do you approve?
                        </Text>
                        <Button onClick={() => navigate('/cx/' + routeParams.cx_id + '/approve')}>
                            Approve
                        </Button>
                        <Button onClick={() => navigate('/cx/' + routeParams.cx_id + '/deny')}>
                            Reject
                        </Button>

                    </div>
                )
            }
            else { // cxResponse.results should === 'approved'
                return (
                    <>
                        <TopBar />
                        <Box w={'100%'} p={2}>
                            <Center><Heading>Approved!</Heading></Center>
                            <Text>You approved {cxResponse.sender_email}'s request to send you reminders.</Text>
                            <Text>You can also send reminders to {cxResponse.sender_email}! How would you like to refer to them in your reminders?</Text>
                            {/*<Text>Set nickname(s) for {cxResponse.sender_email}:</Text>*/}
                            <Box>
                                <form onSubmit={handleSubmit}>
                                    <NicknameInput newUserFormState={newUserFormState} setNewUserFormState={setNewUserFormState}/>
                                    <Button color={'gray.600'} bg={'brand.pink'} isLoading={updateIsLoading} type={'submit'}>Update {cxResponse.sender_email}'s nickname(s)</Button>
                                </form>
                            </Box>

                        </Box>
                    </>
                )
            }
        }
        else {
            if (cxResponse.results === 'approved') {
                return (
                    <>
                        <TopBar />
                        <Text>You approved {cxResponse.sender_email}'s request to send you reminders.</Text>
                    </>
                )
            }
            else if (cxResponse.results === 'denied') {
                return (
                    <>
                        <TopBar />
                        <Text>You denied {cxResponse.sender_email}'s request to send you reminders.</Text>
                    </>
                )
            }
            else {
                return <Navigate to={'/'} />
            }


        }
    }

    else if (cxResponse.user === "sender") {
        if (cxResponse.results === 'pending') {
            return (
                <>
                    <TopBar />
                    <Box w={"100%"} p={2}>
                        <Text fontSize={'xl'}>Your request to add <Text as={'span'} fontWeight={'bold'}>{cxResponse.receiver_email} </Text>
                            to your loop is still pending. We'll let you know when they respond to your request.</Text>
                    </Box>
                </>
                
            )
        }
        else if (cxResponse.results === 'approved') {
            return (
                <>
                    <TopBar />
                    <Box w={"100%"} p={2}>
                        <Text fontSize={'xl'}>You are now connected to <Text as={'span'} fontWeight={'bold'}>{cxResponse.receiver_email} </Text>
                            and can send them reminders.</Text>
                    </Box>
                </>
            )
        }
        else if (cxResponse.results === 'denied') {
            return (
                <>
                    <TopBar />
                    <Box w={"100%"} p={2}>
                        <Text fontSize={'xl'}>Your request to add <Text as={'span'} fontWeight={'bold'}>{cxResponse.receiver_email} </Text>
                            to your loop was denied.</Text>
                    </Box>
                </>
            )
        }
        else {
            return <Navigate to={'/'} />
        }
    }

    else {
        return <Navigate to={'/'} />
    }
}

export default Connection;

import React, { useState, useRef, useEffect, useContext } from 'react';
import { HStack, VStack, Heading, Text, Button, Spacer, Box, Icon, Wrap, WrapItem, Center, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'; 
import {doesSessionExist, useSessionContext} from "supertokens-auth-react/recipe/session";
import {UserContext} from "../UserContext";
import { useNavigate } from 'react-router-dom';
import { ConstructionOutlined, ContactSupportOutlined } from '@mui/icons-material';

const SegmentedControl = ({
    name,
    segments,
    callback,
    defaultIndex = 0,
    controlRef
  }) => {
    const [activeIndex, setActiveIndex] = useState(defaultIndex);
    const componentReady = useRef();
  
    // Determine when the component is "ready"
    useEffect(() => {
      componentReady.current = true;
    }, []);
  
    useEffect(() => {
      const activeSegmentRef = segments[activeIndex].ref;
      const { offsetWidth, offsetLeft } = activeSegmentRef.current;
      const { style } = controlRef.current;
  
      style.setProperty("--highlight-width", `${offsetWidth}px`);
      style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
    }, [activeIndex, callback, controlRef, segments]);
  
    const onInputChange = (value, index) => {
      setActiveIndex(index);
      callback(value, index);
    };
  
    return (
      <div className="controls-container" ref={controlRef}>
        <div className={`controls ${componentReady.current ? "ready" : "idle"}`}>
          {segments?.map((item, i) => (
            <div
              key={item.value}
              className={`segment ${i === activeIndex ? "active" : "inactive"}`}
              ref={item.ref}
            >
              <input
                type="radio"
                value={item.value}
                id={item.label}
                name={name}
                onChange={() => onInputChange(item.value, i)}
                checked={i === activeIndex}
              />
              <label htmlFor={item.label}>{item.label}</label>
            </div>
          ))}
        </div>
      </div>
    );
  };

const DecorativePricingTable = ({openSignInUpModal, closeChosePlanModal, size, navToHome}) => {
    const [isAnnual, setIsAnnual] = useState(true);
    // const [proPriceString, setProPriceString] = useState("$4/month (billed annually)");
    // const [proPaymentLink, setProPaymentLink] = useState(process.env.REACT_APP_STRIPE_ANNUAL_LINK);
    const [proLoading, setProLoading] = useState(false);
    // let session = useSessionContext();
    const user = useContext(UserContext);

    const controlRef = useRef(null);
    const monthlyRef = useRef(null);
    const annuallyRef = useRef(null);

    const navigate = useNavigate();

    const segments = [
        { label: "Quarterly Billing", value: "quarterly", ref: monthlyRef },
        { label: "Annual Billing", value: "annually", ref: annuallyRef }
    ];

    const handleSegmentChange = (value, index) => {
        // Assuming the first index (0) is for quarterly billing and the second (1) is for annual billing
        setIsAnnual(index === 1); // Set isAnnual true if the second segment (annually) is selected
        // console.log(value, index); // For debugging purposes
    };

    const handleFreeClick = async () => {
        // console.log("Free Plan Clicked");
        // user.makeChoice('free');
        
        if (!await doesSessionExist()) {
            openSignInUpModal();
        } else {
            if (navToHome) {
                navigate('/');

            } else {
                // close the chose a plan modal
                closeChosePlanModal();
            }
        }
    };

    const handleProClick = async () => {
        // console.log("Pro Plan Clicked");
        if (!await doesSessionExist()) {
            // user is not logged in
            // save that user is trying to pay for an account
            user.makeChoice('pro');
            // show login modal
            openSignInUpModal();
        } else {
            setProLoading(true);
            let obj = {}
            if (isAnnual) {
                obj.lookup_key = "reminderloop_annual"
            } else {
                obj.lookup_key = "reminderloop_quarterly"
            }
            try {
                let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/create-checkout-session", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(obj)
                })
                console.log(res.status)
                if (res.ok) {
                    let data = await res.json();
                    console.log(data)
                    window.location.href = data.url;  // Redirect to Stripe
                } else {
                    console.error("Some error occurred", res.status);
                }
                
            } catch (err) {
                console.log(err);
            } finally {
                setProLoading(false);
            }
            
        };
    };

    // useEffect(() => {
    //     // setProPriceString(isAnnual ? "$4/month (billed annually)" : "$5/month (billed quarterly)");
    //     setProPaymentLink(isAnnual ? process.env.REACT_APP_STRIPE_ANNUAL_LINK : process.env.REACT_APP_STRIPE_QUARTERLY_LINK);
    // }, [isAnnual]);

    const defaultIndex = isAnnual ? 1 : 0;
    
    // const rLoopHighlightColor = "brand.pink"; // Use a Chakra UI color or a custom color
    const freePlan = {
        title: "Starter",
        price: "Always Free",
        description: "The essentials to get you started with personal reminders:",
        features: [
            'Create up to 5 reminders monthly',
            'No payment info required',
            'Freedom to upgrade any time for more features',
        ],
        cta: doesSessionExist ? "Keep Free Account" : "Begin for Free",
        backgroundColor: "whiteAlpha.800",
        buttonColor: "brand.light_brown",
        // buttonLink: "noPaymentlink",
        callback: handleFreeClick
    };
    const premiumPlan = {
        title: "Pro",
        price: isAnnual ? "$1.67" : "$2",
        description: "Unleash the full potential of ReminderLoop for you and your network:",
        features: [
            'Create unlimited reminders',
            'Send reminders to friends and family',
            'Custom notification options for your convenience',
            'Try for free for 14 days and cancel anytime!'
        ],
        cta: doesSessionExist ? "Upgrade to Pro!" : "Go Pro!",
        backgroundColor: "whiteAlpha.900",
        buttonColor: "brand.yellow",
        // buttonLink: proPaymentLink,
        callback: handleProClick
    };
    // console.log(size);

    // const handleToggle = () => {
    //     setIsAnnual(!isAnnual);
    //     // toast({
    //     //     title: isAnnual ? "Switched to Quarterly Pricing" : "Switched to Annual Pricing",
    //     //     status: "info",
    //     //     duration: 2000,
    //     //     isClosable: true,
    //     // });
    // };
    if (size === "mobile") {
        return (
            <VStack py={5}>
                <SegmentedControl
                    name="price-toggle"
                    segments={segments}
                    // callback={(value, index) => console.log(value, index)}
                    callback={handleSegmentChange}
                    defaultIndex={defaultIndex}
                    controlRef={controlRef}
                />
                <Wrap 
                    // p={12} 
                    spacing={"10px"} 
                    justify={'center'}
                    shouldWrapChildren={true}
                    overflow={'visible'}
                >
                    <WrapItem>
                        {DescriptiveColumn(premiumPlan, isAnnual, segments, controlRef)}
                    </WrapItem>
                    <WrapItem>
                        {DescriptiveColumn(freePlan)}
                    </WrapItem>
                </Wrap>
            </VStack>

        );
    } else {
        return (
            <Box>
                <SegmentedControl
                    name="price-toggle"
                    segments={segments}
                    // callback={(value, index) => console.log(value, index)}
                    callback={handleSegmentChange}
                    defaultIndex={defaultIndex}
                    controlRef={controlRef}
                />
                <HStack pt={4} pl={4} pr={4} spacing={6} justify={'center'}>
                    {DescriptiveColumn(freePlan)}
                    {DescriptiveColumn(premiumPlan, isAnnual, segments, controlRef, proLoading)}
                </HStack>
            </Box>
        );
    }
};

const DescriptiveColumn = (plan, isAnnual, segments, controlRef, proLoading) => {
    return (
        <Box 
            w={{base: "2xs", sm: "2xs", md: "2xs", lg: "2xs", xl: "xs", "2xl": "xs"}} 
            h={{base: "xl", sm: "xl", md: "xl", lg: "xl", xl: "xl", "2xl": "2xl"}} 
            shadow={plan.title==="Pro" ? '0 0 40px 15px #FDE376' : '2xl'} 
            p={2} 
            rounded={'2xl'} 
            bg={plan.backgroundColor}>
            <VStack align={'start'} m={6} spacing={0}>
                {plan.title==="Pro" && <Heading size={'md'}>{plan.title}</Heading>}
                {plan.title==="Starter" && <Heading size={'md'} pb={3}>{plan.title}</Heading>}
                <HStack as={'span'} align={"end"} spacing={0}>
                    {isAnnual && <Text as={"s"} pr={1} fontSize={{base: "xl", sm: "xl", md: "xl", lg: "3xl", xl: "4xl", "2xl": "4xl"}} color={'red'}>$2</Text>}
                    <Text fontSize={{base: "xl", sm: "xl", md: "xl", lg: "3xl", xl: "4xl", "2xl": "4xl"}}>{plan.price}</Text>
                    {plan.title==="Pro" && <Text pl={1} pb={{base: "4px", sm: "4px", md: "4px", lg: "5px", xl: "8px", "2xl": "8px"}} fontSize={'xs'}>/month</Text>}
                    {isAnnual && <Text fontWeight={'bold'} pb={{lg: "5px", xl: "8px", "2xl": "8px"}} pl={2} fontSize={'sm'} color={'red'}>{"save 17%!"}</Text>}
                </HStack>
                {plan.title==="Pro" && (isAnnual ? <Text color={'blackAlpha.700'} pb={5} as={"i"} fontSize={"sm"}>{"(billed annually)"}</Text> : <Text color={"blackAlpha.700"} pb={5} as={"i"} fontSize={"sm"}>{"(billed quarterly)"}</Text>)}
                {plan.title==="Starter" && <Box h={7}/>}
                <Button
                    height={"50px"}
                    bg={plan.buttonColor}
                    color={plan.title==="Pro" ? "black" : 'blackAlpha.700'}
                    boxShadow={plan.title==="Pro" && 'dark-lg'}
                    fontSize={{base: 'xl', sm: 'xl', md: '2xl'}}
                    isLoading={plan.title==="Pro" && proLoading}
                    // onClick={() => window.location.href = plan.buttonLink}
                    // onClick={() => window.open(plan.buttonLink, '_blank')}
                    onClick={plan.callback}
                    w={'100%'}
                    _hover={plan.title==="Pro" && {
                        // bg: 'brand.yellow_hover', // darken the button on hover
                        transform: 'scale(1.05)', // scale up the button on hover
                        boxShadow: 'dark-lg', // even larger shadow on hover
                        }}
                >
                    {plan.cta}
                </Button>
                <Text 
                    pt={8} 
                    fontWeight={"bold"} 
                    fontSize={{lg: "md", xl: "lg", "2xl": "lg"}}
                    color={"blackAlpha.700"}
                >
                    {plan.description}
                </Text>
                <VStack align={'start'} spacing={4} pt={4}>
                    {plan.features.map((feature, index) => (
                        <HStack key={index}>
                            <Icon as={CheckIcon} color="brand.green_hover" />
                            {feature==='Try for free for 14 days and cancel anytime!' ? <Text fontWeight={'bold'} color={'blackAlpha.800'} lineHeight={'shorter'}>{feature}</Text> :
                            <Text 
                                color={'blackAlpha.800'}
                                fontSize={{lg: "md", xl: "lg", "2xl": "lg"}} 
                                lineHeight={'shorter'}
                            >
                                {feature}
                            </Text>}
                        </HStack>
                    ))}
                </VStack>
                
            </VStack>
        </Box>
    );
};


export default DecorativePricingTable;
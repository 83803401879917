import React, { useState, useEffect, useContext, useRef } from 'react';
// import { DeleteIcon, EditIcon, CloseIcon, ViewOffIcon, CheckCircleIcon, MinusIcon } from "@chakra-ui/icons";
import {
    Box,
    Center,
    Spacer,
    Text,
    HStack,
    Flex,
    Modal,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Input, useToast, Wrap, Grid, GridItem, Spinner, Avatar, Tooltip, Icon, useBreakpointValue
} from "@chakra-ui/react"
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
// import { createColumnHelper} from "@tanstack/react-table";
import {UserContext} from "../UserContext";
// import EditReminderModal from "./EditReminderModal";
// import { Logtail } from "@logtail/browser";
// import { ContactSupportOutlined, Grid3x3 } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import FlagRoundedIcon from '@mui/icons-material/FlagRounded';



function DummyReminders({reminderToAdd, openSignInUpModal}){
    const [isLoading, setIsLoading] = useState(true);
    const [isOverflowed, setIsOverflowed] = useState(false);
    const listRef = useRef(null);
    const user = useContext(UserContext);
    let user_details = user.user_details;
    // const toast = useToast()
    // const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN)
    let [reminderList, setReminderList] = useState([])
    let dummyReminders = generateDummyReminders(4);
    // const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isFlagModalOpen, setIsFlagModalOpen] = useState(false);
    const [reminderUuidToEdit, setReminderUuidToEdit] = useState(null);
    const [editReminderAction, setEditReminderAction] = useState('');
    const [editFireDate, setEditFireDate] = useState('');
    const [nowDateTimeString, setNowDateTimeString] = useState('');

    const openEditModal = () => setIsEditModalOpen(true);
    const closeEditModal = () => setIsEditModalOpen(false);
    const openFlagModal = () => setIsFlagModalOpen(true);
    const closeFlagModal = () => setIsFlagModalOpen(false);

    const toFromfontSize = useBreakpointValue({ lg: "sm", xl: "sm", "2xl": "md" });

    
    useEffect(() => {
        // console.log('====dummy reminders====')
        // console.log(dummyReminders)
        setReminderList(dummyReminders)
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if(reminderToAdd && reminderToAdd.action){
            addUserDummyReminder(reminderToAdd.action, reminderToAdd.datetime, reminderToAdd.targets[0])
        }
        // console.log('reminderToAdd is ' + reminderToAdd.action)
    }, [reminderToAdd]);

    useEffect(() => {
        const checkOverflow = () => {
          const current = listRef.current;
          if (!current) {
            return;
          }
    
          // Set state based on overflow
          setIsOverflowed(current.clientHeight < current.scrollHeight);
        };
    
        // Run on mount and every time the list changes
        checkOverflow();
    
        // Optional: if you expect dynamic content changes or window resizing
        window.addEventListener('resize', checkOverflow);

        // console.log("isOverFlowed is " + isOverflowed)
    
        // Cleanup event listener
        return () => window.removeEventListener('resize', checkOverflow);
      }, [reminderList]); // Depend on reminderList, add other dependencies if needed

    
      function addUserDummyReminder(action, datetimeString, target) {
        // console.log('action is ' + action);
        // console.log('datetime is ' + datetimeString);
        // console.log('target is ' + target);
    
        const id = generateRandomUUID();
        const now = new Date();
        // Convert the datetime string to a Date object
        // Assuming datetimeString is in "MM/DD/YY HH:mm" format
        const datetime = new Date(datetimeString);
        let target_user_id = target;

        if (target === "self") {
            target_user_id = "user_id_1"; 
        }
    
        const newReminder = {
            id: id,
            uuid: generateRandomUUID(),
            job_id: `job_${id}`,
            owner_id: "user_id_1", // Assuming a fixed user for simplicity
            user_timezone: "UTC",
            channel_id: 1, // Assuming a fixed channel for simplicity
            // raw_input: "Reminder: " + randomAction,
            // open_ai_response: "You need to " + randomAction,
            reminder_action: action,
            local_fire_date_time: datetime.toISOString(),
            fire_date_time: datetime.toISOString(),
            // is_corrected: false,
            // corrected_action: "",
            // corrected_fire_date_time: "",
            // corrected_local_fire_date_time: "",
            // corrected_target_user_id: "",
            // corrected_channel_id: 1,
            status: "pending",
            target_user_id: target_user_id,
            sharable: true,
            executed: false,
            apprise_settings: "",
            date_added: now.toISOString(),
            // open_ai_input: "What reminders do I have?",
            last_updated: now.toISOString(),
            is_hidden: false,
            // ai_model: "GPT-3",
            // ai_tokens: "",
            is_snoozed: false,
            sent: false,
            from_user: true,
            // expanded: false,
        }
    
        const reminders = [newReminder, ...reminderList];
        // reminders.push(newReminder);
        setReminderList(reminders);
    
    }
    
    function handleDelete(e) {
        // console.log(e.target.parentElement.id)
        // console.log(e.currentTarget.id + " clicked")
        const reminderIdToDelete = e.currentTarget.id;

        // Filter out the reminder that needs to be deleted using its uuid
        const updatedReminders = reminderList.filter(reminder => reminder.uuid !== reminderIdToDelete);

        // Update the state with the filtered reminders list
        setReminderList(updatedReminders);
        
    }

    function handleFlag(e){
        openFlagModal();
    }

    function handleFlagClose(){
        closeFlagModal();
        openSignInUpModal();
    }

    function handleEdit(uuid){
        // console.log(e.target.parentElement.id)
        // console.log(uuid + " edit clicked")
        setReminderUuidToEdit(uuid)
        // get the reminder from reminderList based on reminder uuid
        const reminderToEdit = reminderList.find(reminder => reminder.uuid === uuid);
        
        // console.log(reminderToEdit.local_fire_date_time)
        const editFireDate = convertToDateTimeLocalString(reminderToEdit.local_fire_date_time);

        const now = new Date();
        const dateTimeString = now.toISOString().substring(0,16);
        setNowDateTimeString(dateTimeString);


        setEditFireDate(editFireDate);
        setEditReminderAction(reminderToEdit.reminder_action);

        openEditModal();

    }

    const handleEditSubmit = () => {
        // Create a new array where the reminder with the matching UUID is updated
        const updatedReminders = reminderList.map(reminder => {
            if (reminder.uuid === reminderUuidToEdit) {
                // This is the reminder we want to edit. Return a new object
                // with the updated action and fire date.
                return {
                    ...reminder,
                    reminder_action: editReminderAction,
                    local_fire_date_time: editFireDate,
                };
            } else {
                // This is not the reminder we want to edit. Return it as is.
                return reminder;
            }
        });
    
        // Update the reminder list
        setReminderList(updatedReminders);
        closeEditModal();
    };

    function buildDummyToFromBox(reminder){        
        if (reminder.from_user) {
            // decide what type of box is needed
            if (reminder.owner_id === reminder.target_user_id) {
                return (
                    <Box></Box>
                )
            } else {
                //build sender icon
                const target = reminder.target_user_id;
                const capitalizedTarget = target.charAt(0).toUpperCase() + target.slice(1);
                return (
                    <Tooltip label={"You sent this reminder to " + capitalizedTarget} >
                        <Box bg={'brand.green'} pr={2} pl={2} borderRadius={'full'}>
                            <HStack>
                                <Text color={'blackAlpha.700'} fontWeight={'bold'} >
                                    To
                                </Text>
                                <Avatar size='2xs' name={capitalizedTarget} src='./dan.webp' />
                            </HStack>
                        </Box>
                    </Tooltip>
                )
            }    
        } else {
            // console.log('this is dummy generated reminder:' + reminder.reminder_action);
            // decide randomly if the reminder is to someone, from someone, or for self
            const lastChar = reminder.uuid.slice(-1);
            const secondLastChar = reminder.uuid.slice(-2, -1);
            const num = lastChar.charCodeAt(0) % 10;
            // select a random number between 0 and 5
            // const random = Math.floor(Math.random() * 4);
            const random = secondLastChar.charCodeAt(0) % 4;
            let name = "Jen";
            let img = "./jen.jpeg";
            switch (random) {
                case 0:
                    name = "Jen";
                    img = "./jen.jpeg";
                    break;
                case 1:
                    name = "John";
                    img = "./john.jpeg";
                    break;
                case 2:
                    name = "Sam";
                    img = "./sam.jpeg";
                    break;
                case 3:
                    name = "Sarah";
                    img = "./sarah.jpeg";
                    break;
                case 4:
                    name = "Tommy";
                    img = "./tommy.jpeg";
                    break;  
                default:
                    name = "Jen";
                    img = "./jen.jpeg";
                    break;
            }
            if (num < 4) {
                //build receiver icon
                return (
                    <Tooltip label={name + " sent this reminder to you"} fontSize={'md'}>
                        <Box bg={'brand.green'} pr={2} pl={2} borderRadius={'full'}>
                            <HStack>
                                <Text color={'blackAlpha.700'} fontWeight={'bold'}>
                                    From
                                </Text>
                                <Avatar size='2xs' name={name} src={img} />
                            </HStack>
                        </Box>
                    </Tooltip>
                )
            } else if (num < 7) {
                // build sender icon
                return (
                    <Tooltip label={"You sent this reminder to " + name} fontSize={'md'}>
                        <Box bg={'brand.green'} pr={2} pl={2} borderRadius={'full'}>
                            <HStack>
                                <Text color={'blackAlpha.700'} fontWeight={'bold'}>
                                    To
                                </Text>
                                <Avatar size='2xs' name={name} src={img} />
                            </HStack>
                        </Box>
                    </Tooltip>
                )
            } else {
                // return empty box
                return (
                    <Box></Box>
                )
            }
        }
    }

    function buildToFromBox(reminder){
        if (reminder.owner_id !== user_details.supertokens_id){
            //build receiver icon
            return (
                <Box bg={'brand.green'} pr={2} pl={2} borderRadius={'full'}>
                    <HStack>
                        <Text color={'blackAlpha.700'} fontWeight={'bold'}>
                            From
                        </Text>
                        <Avatar size='2xs' name='Dan Abrahmov' src='https://bit.ly/dan-abramov' />
                    </HStack>
                </Box>
            )
        }
        else if (reminder.target_user_id !== user_details.supertokens_id){
            // build sender icon
            return (
                <Box bg={'brand.green'} pr={2} pl={2} borderRadius={'full'}>
                    <HStack>
                        <Text color={'blackAlpha.700'} fontWeight={'bold'}>
                            To
                        </Text>
                        <Avatar size='2xs' name='Dan Abrahmov' src='https://bit.ly/dan-abramov' />
                    </HStack>
                </Box>
            )
        }
        else {
            // return empty box
            return (
                <Box></Box>
            )
        }
    }

    const buildReminder = (reminder) => {
        const date = new Date(reminder.local_fire_date_time);
        const formattedDate = date.toLocaleString('en-US', {
            month: 'long', // Display the full month name
            day: 'numeric', // Display the day as a number
            year: 'numeric', // Display the full year
            hour: 'numeric', // Display the hour
            minute: '2-digit', // Display the minute with at least 2 digits
            // second: '2-digit', // Display the second with at least 2 digits
        });
        
        return (
            // <Accordion 
            //     allowToggle 
            //     border={"0px"}
            //     // onChange={handleExpandedReminder(reminder.uuid)}
            // >
                <AccordionItem key={reminder.uuid} border={"0px"} pb={1}>
                    {({ isExpanded }) => (
                        <>
                            <Grid
                                templateColumns={'repeat(23, 1fr)'}
                                templateAreas={`"check check check action action action action action action action action action action action action action action tofrom tofrom tofrom tofrom accordion accordion"
                                                "check check check time time time time time time time time time time time time time time tofrom tofrom tofrom tofrom accordion accordion"`}
                                // templateRows='repeat(2, 1fr)'
                                // templateColumns='repeat(20, 1fr)'
                                gap={0}
                                bg={'whiteAlpha.700'}
                                // borderRadius={isExpanded ? "3xl 3xl 0 0" : "3xl"}
                                borderTopRadius={"3xl"}
                                borderBottomRadius={isExpanded ? "0" : "3xl"}
                                
                                >
                                <GridItem
                                    // bg={'red.100'}
                                    area={'check'}
                                    display="flex" // This enables the flexbox layout
                                    justifyContent="center" // This centers the content horizontally
                                    alignItems="center" // This centers the content vertically
                                >
                                    <Center>
                                        {/* <IconButton bg={"brand.green"} size={'lg'} onClick={handleDelete} icon={<CheckCircleIcon />} aria-label={"Checkmark Button"} mr={1} id={reminder.uuid}/> */}
                                        <Icon 
                                            as={CheckCircleOutlineIcon} 
                                            id={reminder.uuid} 
                                            onClick={handleDelete} 
                                            boxSize={{lg: 8, xl: 10, "2xl": 12}}
                                            style={{color: 'grey', cursor: 'pointer'}}
                                        />
                                        {/* <CheckCircleOutlineIcon
                                            id={reminder.uuid}
                                            onClick={handleDelete}
                                            style={{
                                            color: 'grey', // Use your brand color here
                                            cursor: 'pointer',
                                            fontSize: {lg: 40, xl: 36, "2xl": 40} // Adjust the size as needed
                                            }}
                                        /> */}
                                    </Center>
                                </GridItem>
                                
                                <GridItem bg={''} area={'action'}>
                                    <Text isTruncated minWidth={"0"} fontSize={{lg: "lg", xl: "xl", "2xl": "2xl"}} color={'blackAlpha.800'}>{reminder.reminder_action}</Text>
                                </GridItem>
                            
                                <GridItem bg={''} area={'time'}>
                                    <Text fontSize={{lg: "sm", xl: "sm", "2xl": "md"}} color={'blackAlpha.800'}>{formattedDate}</Text>
                                </GridItem>
                                <GridItem
                                    area={'tofrom'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    {buildDummyToFromBox(reminder)}
                                </GridItem>
                                <GridItem
                                    // bg={'red.100'}
                                    id='accordion-button-grid'
                                    area={'accordion'}
                                    display="flex" // This enables the flexbox layout
                                    justifyContent="center" // This centers the content horizontally
                                    alignItems="center" // This centers the content vertically
                                >
                                    <AccordionButton >
                                        <Center id='accordion-button-center' w={"100%"}>
                                            <AccordionIcon />
                                        </Center>
                                    </AccordionButton>
                                </GridItem>
                                
                            </Grid>
                            <AccordionPanel
                                bg={'whiteAlpha.700'}
                                borderBottomRadius={"3xl"}
                                pb={2}
                            >
                                <Flex direction={"row"}>
                                    <Spacer />
                                    <Button
                                        size={{base: "sm", sm: "sm", md: "sm", lg: "sm", xl: "md", "2xl": "md"}} 
                                        leftIcon={<EditRoundedIcon style={{color: 'white'}} />} 
                                        bg={'brand.violet'}
                                        onClick={() => handleEdit(reminder.uuid)} 
                                        _hover={{
                                            bg: 'brand.violet_hover', // darken the button on hover
                                            // transform: 'scale(1.05)', // scale up the button on hover
                                            boxShadow: 'xl', // even larger shadow on hover
                                        }}   
                                    >
                                        <Text color={'white'} fontWeight={'bold'}>
                                            Edit
                                        </Text>
                                    </Button>
                                    <Spacer />
                                    <Button 
                                        size={{base: "sm", sm: "sm", md: "sm", lg: "sm", xl: "md", "2xl": "md"}} 
                                        leftIcon={<FlagRoundedIcon style={{color: 'white'}} />} 
                                        bg={'brand.violet'}
                                        onClick={handleFlag}
                                        _hover={{
                                            bg: 'brand.violet_hover', // darken the button on hover
                                            // transform: 'scale(1.05)', // scale up the button on hover
                                            boxShadow: 'xl', // even larger shadow on hover
                                        }}
                                    >
                                        <Text color={'white'} fontWeight={'bold'}>
                                            Flag a problem
                                        </Text>
                                    </Button>
                                    <Spacer />
                                </Flex>
                                
                            </AccordionPanel>
                        </>
                    )}
                </AccordionItem>
            //</Accordion>

        );
    }
    if (isLoading) {
        return (
            <Center>
                <Spinner size="xl" />
            </Center>
        );
    }

    return (
        <Center flex={"1"} >
            {/* Edit Modal */}
            <Modal isOpen={isEditModalOpen} onClose={closeEditModal} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Reminder
                        <ModalCloseButton />
                    </ModalHeader>
                    <ModalBody>
                        
                        <Input 
                            type={'text'} 
                            name={'new_reminder_action'} 
                            value={editReminderAction}
                            onChange={(e) => setEditReminderAction(e.target.value)}
                        />
                        {/* TODO: This renders the date/time picker in the browser's local time.  May need to fix this in the future */}
                        <Input 
                            type={'datetime-local'} 
                            min={nowDateTimeString}
                            value={editFireDate}
                            onChange={(e) => setEditFireDate(e.target.value)}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Wrap>
                            <Button 
                                bg={'brand.green'}
                                onClick={handleEditSubmit}
                                _hover={{
                                    bg: 'brand.green_hover', // darken the button on hover
                                    // transform: 'scale(1.05)', // scale up the button on hover
                                    boxShadow: 'xl', // even larger shadow on hover
                                }}   
                            >
                                Submit
                            </Button>
                        </Wrap>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {/* Flag Modal */}
            <Modal isOpen={isFlagModalOpen} onClose={closeFlagModal} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Flag a problem
                        <ModalCloseButton />
                    </ModalHeader>
                    <ModalBody>
                        <Text>This doesn't work in demo mode, but this is where you would tell us if you expected a different reminder based on what you told us.</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Wrap>
                            <Button 
                                bg={'brand.green'}
                                onClick={handleFlagClose}
                                _hover={{
                                    bg: 'brand.green_hover', // darken the button on hover
                                    // transform: 'scale(1.05)', // scale up the button on hover
                                    boxShadow: 'xl', // even larger shadow on hover
                                }}   
                            >
                                Sign Up Now!
                            </Button>
                        </Wrap>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Box w={'100%'} 
                // maxH={'275px'} 
                maxH={{base: "220", sm: "220", md: "250", lg: "220", xl: "230", "2xl": "275"}}
                // flex={"1"} 
                overflowY="auto"
                position={"relative"}
                overflowX={"hidden"}
                ref={listRef}
            >
                <Accordion
                    allowMultiple
                    border={"0px"}
                >
                    {reminderList.map(reminder => buildReminder(reminder))}
                </Accordion>
            </Box>
            {isOverflowed && (
                <Box
                    position="absolute"
                    bottom="0"
                    left="0"
                    right="0"
                    height="40px"
                    bgGradient="linear(to-b, transparent, rgba(217, 227, 243, 0.5)"
                />
            )}
        </Center>
    );

}

export default DummyReminders;



function generateRandomUUID() {
    // Simple function to generate a random UUID-like string for demo purposes
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

function createDummyReminder(id) {
    const now = new Date();
    const reminderActions = [
        "Take the trash out", "Call the tax advisor", "Pick up milk",
        "Set the chicken out to thaw", "Water the plants", "Check the mail",
        "Schedule a doctor's appointment", "Pay the electricity bill",
        "Walk the dog", "Prepare for tomorrow's presentation", "Review the budget report", 
        "Send birthday card to Sarah", "Book car service appointment", 
        "Update antivirus software", "Read chapter 4 of the book club selection", 
        "Order groceries online", "Start preparing tax documents", "Renew gym membership", 
        "Plan weekend family outing", "Check kids' school assignments", "Clean the kitchen", 
        "Organize the home office", "Email project update to the team", 
        "Practice yoga for 30 minutes", "Return library books", "Buy birthday gift for Alex", 
        "Schedule annual health checkup", "Backup computer files", "Prepare presentation for Monday's meeting", 
        "Call plumber for sink repair"
    ];

    // Select a random action from the list
    const randomAction = reminderActions[Math.floor(Math.random() * reminderActions.length)];

    return {
        id: id,
        uuid: generateRandomUUID(),
        job_id: `job_${id}`,
        owner_id: "user_id_1", // Assuming a fixed user for simplicity
        user_timezone: "UTC",
        channel_id: 1, // Assuming a fixed channel for simplicity
        // raw_input: "Reminder: " + randomAction,
        // open_ai_response: "You need to " + randomAction,
        reminder_action: randomAction,
        local_fire_date_time: now.toISOString(),
        fire_date_time: now.toISOString(),
        // is_corrected: false,
        // corrected_action: "",
        // corrected_fire_date_time: "",
        // corrected_local_fire_date_time: "",
        // corrected_target_user_id: "",
        // corrected_channel_id: 1,
        status: "pending",
        target_user_id: "user_id_2",
        sharable: true,
        executed: false,
        apprise_settings: "",
        date_added: now.toISOString(),
        // open_ai_input: "What reminders do I have?",
        last_updated: now.toISOString(),
        is_hidden: false,
        // ai_model: "GPT-3",
        // ai_tokens: "",
        is_snoozed: false,
        sent: false,
        from_user: false,
        expanded: false,
    };
}


function generateDummyReminders(count) {
    const reminders = [];
    for (let i = 0; i < count; i++) {
        reminders.push(createDummyReminder(i + 1));
    }
    return reminders;
}

function convertToDateTimeLocalString(isoString) {
    const date = new Date(isoString);
    // Get YYYY-MM-DD format
    const datePart = date.toISOString().split('T')[0];
    // Get hh:mm format - convert to local time
    let hours = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');

    // Combine for datetime-local input value
    return `${datePart}T${hours}:${minutes}`;
}
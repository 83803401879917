import React, {useEffect, useState, useContext} from "react";
import {UserContext} from "../UserContext";
import {
    Box,
    Button,
    Center,
    Flex, FormControl, FormLabel,
    Grid,
    GridItem,
    Heading,
    HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay,
    Text,
    useDisclosure,
    useToast, VStack, Wrap
} from "@chakra-ui/react";
import { NicknameInput } from "./TxRxReminders";
import {useSessionContext} from "supertokens-auth-react/recipe/session";
import { redirect, useNavigate } from "react-router-dom";
import { Logtail } from "@logtail/browser";
import DecorativePricingTable from "./DecorativePricingTable";

function RemindersForOthersDisplay({ openSignUpModal }) {
    const user = useContext(UserContext)
    let user_details = user.user_details
    const [senderArray, setSenderArray] = useState([])
    const [receiverArray, setReceiverArray] = useState([])
    const [newLooperPopNeeded, setNewLooperPopNeeded] = useState(false)
    const toast = useToast()
    // const templateColString = '1fr 165px'
    const templateColString = 'auto 165px'
    const navigate = useNavigate();
    const [editNicknamePopNeeded, setEditNicknamePopNeeded] = useState(false)
    const [nickNameChangeId, setNickNameChangeId] = useState(null)
    const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN)
    const { isOpen: isPricingTableModalOpen, onOpen: onPricingModalOpen, onClose: onPricingModalClose } = useDisclosure();

    useEffect(() => {
        if (user_details.sender.length > 0) {
            let sArray = []
            let rArray = []
            user_details.sender.forEach((sender) => {
                // console.log(sender.receiver_name)
                // let font_color = 'black'
                if (sender.is_rejected) {
                    return
                }
                if (sender.is_pending) {
                    // font_color = 'gray.500'
                    // console.log("sender ispending")
                    sArray.push(
                    <>
                        <GridItem rowSpan={1} colSpan={1}>
                            <Box pl={2} pr={2} pt={2} borderRadius={'md'} > {/*boxShadow={'md'}*/}
                                <Wrap verticalAlign={'bottom'}>
                                    <Text color={'gray.500'} display={'inline'} fontSize={'xl'}>{sender.receiver_name[0]}</Text>
                                    <Text color={'gray.500'} display={'inline'} fontSize={'sm'} pl={4} >{sender.receiver_email}</Text>
                                    <Text color={'gray.500'} display={'inline'} fontSize={'sm'} pl={0} as={'i'}>pending</Text>
                                </Wrap>
                            </Box>
                        </GridItem>
                        <GridItem rowSpan={1} colSpan={1}>
                            <HStack h={'100%'} justify={'end'} pr={2}>
                                <Button size={'sm'} bg={'brand.green'}>Edit</Button>
                                <Button size={'sm'} bg={'red.300'} id={sender.id} onClick={handleDelete}>Delete</Button>
                            </HStack>
                        </GridItem>
                        <GridItem pl={7} rowSpan={1} colSpan={1}>
                            {/*<Text>{sender.receiver_email}</Text>*/}
                        </GridItem>
                        <GridItem rowSpan={1} colSpan={2}>
                            <Box w={'100%'} h={'1px'} bg={'gray.300'} mt={2}/>
                        </GridItem>
                    </>
                    )
                }
                else {
                    sArray.push(
                        <>
                            <GridItem rowSpan={1} colSpan={1}>
                                <Box pl={2} pr={2} pt={2} borderRadius={'md'}> {/*boxShadow={'md'}*/}
                                    <Wrap verticalAlign={'bottom'}>
                                        <Text display={'inline'} fontSize={'xl'}>{sender.receiver_name[0]}</Text>
                                        <Text display={'inline'} fontSize={'sm'} pl={4}>{sender.receiver_email}</Text>
                                    </Wrap>
                                </Box>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={1}>
                                <HStack h={'100%'} justify={'end'} pr={2}>
                                    <Button size={'sm'} bg={'brand.green'} id={sender.id} onClick={handleNicknameClick}>Nicknames</Button>
                                    <Button size={'sm'} bg={'red.300'} id={sender.id}
                                            onClick={handleDelete}>Delete</Button>
                                </HStack>
                            </GridItem>
                            <GridItem pl={7} rowSpan={1} colSpan={1}>
                                {/*<Text>{sender.receiver_email}</Text>*/}
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={2}>
                                <Box w={'100%'} h={'1px'} bg={'gray.300'} mt={2}/>
                            </GridItem>
                        </>
                    )
                }
            })
            if (sArray.length === 0) {
                sArray.push(
                    <GridItem rowSpan={1} colSpan={1}>
                        <Box pl={2} pr={2} pt={2} borderRadius={'md'} > {/*boxShadow={'md'}*/}
                            <Text display={'inline'} fontSize={'xl'}>
                                No one yet!
                            </Text>
                        </Box>
                    </GridItem>
                )
            }
            setSenderArray(sArray)

            user_details.receiver.forEach((receiver) => {
                // console.log(sender.receiver_name)
                // let font_color= 'black'
                if (receiver.is_rejected) {
                    return
                }
                if (receiver.is_pending) {
                    // font_color = 'gray.500'
                    // console.log("receiver ispending")
                    rArray.push(
                        <>
                            <GridItem rowSpan={1} colSpan={1}>
                                <Box pl={2} pr={2} pt={2} borderRadius={'md'}> {/*boxShadow={'md'}*/}
                                    <Wrap>
                                        <Text color={'gray.500'} display={'inline'} fontSize={['xl','2xl']}>
                                            {receiver.sender_email}
                                        </Text>
                                        {/*<Text color={'gray.500'} display={'inline'} fontSize={'sm'} pl={2} as={'i'} >*/}
                                        {/*    pending*/}
                                        {/*</Text>*/}
                                    </Wrap>
                                </Box>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={1} >
                                <HStack h={'100%'} justify={'end'} pr={2}>
                                    <Button size={'sm'} bg={'brand.green'} onClick={() => {navigate("/cx/"+receiver.id+"/approve")}}>Approve</Button>
                                    <Button size={'sm'} bg={'red.300'} id={receiver.id} onClick={() => {navigate("/cx/"+receiver.id+"/deny")}}>Deny</Button>
                                </HStack>
                            </GridItem>
                            <GridItem pl={7} rowSpan={1} colSpan={1}>
                                {/*<Text>{receiver.sender_email}</Text>*/}
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={2}>
                                <Box w={'100%'} h={'1px'} bg={'gray.300'} mt={2}/>
                            </GridItem>
                        </>
                    )
                }
                else {
                    rArray.push(
                        <>
                            <GridItem rowSpan={1} colSpan={1}>
                                <Box pl={2} pr={2} pt={2} borderRadius={'md'}> {/*boxShadow={'md'}*/}
                                    <Text display={'inline'} fontSize={'xl'}>
                                        {receiver.sender_email}
                                    </Text>
                                </Box>
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={1}>
                                <HStack h={'100%'} justify={'end'} pr={2}>
                                    <Button size={'sm'} bg={'red.300'} id={receiver.id}
                                            onClick={handleDelete}>Delete</Button>
                                </HStack>
                            </GridItem>
                            <GridItem pl={7} rowSpan={1} colSpan={1}>
                                {/*<Text>{receiver.sender_email}</Text>*/}
                            </GridItem>
                            <GridItem rowSpan={1} colSpan={2}>
                                <Box w={'100%'} h={'1px'} bg={'gray.300'} mt={2}/>
                            </GridItem>
                        </>
                    )
                }
            })
            if (rArray.length === 0) {
                rArray.push(
                    <GridItem rowSpan={1} colSpan={1}>
                        <Box pl={2} pr={2} pt={2} borderRadius={'md'} > {/*boxShadow={'md'}*/}
                            <Text display={'inline'} fontSize={'xl'}>
                                No one yet!
                            </Text>
                        </Box>
                    </GridItem>
                )
            }
            setReceiverArray(rArray)
        }

    }, [user_details.sender, user_details.receiver])

    let handleDelete = (e) => {
        // console.log(e.target.id)
        e.preventDefault()
        const url = process.env.REACT_APP_API_DOMAIN + "/cx/"
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                cx_id: e.target.id,
                action: "delete",
                // user_id: user.user_id
            }),
        }
        try {
            fetch(url, options)
                .then(response => response.json())
                .then(response => {
                    console.log(response)
                    // setCxResponse(response)
                    // setIsLoading(false)
                    // user.setNeedsRefresh(true)
                    if (response.results === "deleted") {
                        user.setNeedsRefresh(true)
                        toast({
                            title: "Success!",
                            description: "Your connection has been deleted.",
                            status: "success",
                            isClosable: true
                        })
                    }
                })
            // let resJson = res.json()
            // console.log(resJson)
        } catch (err) {
            logtail.error(user_details.email + ": something went wrong deleting a user connection", err)
            logtail.flush()
            console.log(err)
            toast({
                title: "Error!",
                description: "Something went wrong. Please try again.",
                status: "error",
                isClosable: true
            })
        }
    }

    let handleLoopClick = (e) => {
        e.preventDefault()
        setNewLooperPopNeeded(true)
    }

    let handleNicknameClick = (e) => {
        e.preventDefault()
        // console.log(e.target.id)
        setNickNameChangeId(e.target.id)
        setEditNicknamePopNeeded(true)
    }

    if (user_details.account_type === 'free') {
        return (
            <>
                <Box p={2}>
                    <Heading py={'40px'} fontSize={'xl'}> Upgrade to a paid account to send and receive reminders from friends and family!</Heading>
                    <Flex w={'100%'} p={1} justify={'center'}>
                        <Button 
                            w={'300px'} 
                            bg={'brand.yellow'} 
                            onClick={onPricingModalOpen} 
                            boxShadow={'md'}
                            _hover={{bg: 'brand.yellow', boxShadow: 'dark-lg'}}>
                            <Text fontSize={'xl'}>
                                Upgrade Now!
                            </Text>
                        </Button>
                    </Flex>
                </Box>
                <Modal isOpen={isPricingTableModalOpen} onClose={onPricingModalClose} size={"2xl"}>
                <ModalOverlay bg={"blackAlpha.700"} backdropFilter='blur(5px)'/>
                <ModalContent h={{lg: "730px", xl: "730px", "2xl": "800px"}} bg={"brand.green"}>
                    <ModalHeader>
                        <ModalCloseButton />
                    </ModalHeader>
                    <ModalBody >
                        <DecorativePricingTable navToHome={true} />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
        )
    }

    if (user_details.sender.length === 0 && user_details.receiver.length === 0) {
        return (
            <Box p={2}>
                <Heading fontSize={'xl'}> You aren't connected to any other users.</Heading>
                <Box w={'100%'} p={1}>
                    <Button w={'100%'} bg={'brand.pink'} onClick={handleLoopClick} _hover={{bg: 'brand.pink_hover'}}>
                        <Text fontSize={'xl'}>
                            Add New User To Your Loop!
                        </Text>
                    </Button>
                </Box>
                {newLooperPopNeeded && <NewLooperPop setNewLooperPopNeeded={setNewLooperPopNeeded}/>}
            </Box>
        )
    }
    else {
        return (
            <Box w={'100%'} maxW={'700px'}>
                <Heading p={1} pt={2} fontSize={'xl'} fontWeight={'normal'}> You can send reminders to:</Heading>
                <Grid gridTemplateColumns={templateColString}>
                    {senderArray}
                </Grid>
                <Box w={'100%'} h={'3px'} bg={'gray.300'}/>
                <Heading p={1} pt={2} fontSize={'xl'} fontWeight={'normal'}> You can receive reminders from:</Heading>
                <Grid gridTemplateColumns={templateColString}>
                    {receiverArray}
                </Grid>
                <Box w={'100%'} h={'3px'} bg={'gray.300'}/>
                <Box w={'100%'} p={1}>
                    <Button w={'100%'} bg={'brand.pink'} onClick={handleLoopClick} _hover={{bg: 'brand.pink_hover'}}>
                        <Text fontSize={'xl'}>
                            Add New User To Your Loop!
                        </Text>
                    </Button>
                </Box>
                {newLooperPopNeeded && <NewLooperPop setNewLooperPopNeeded={setNewLooperPopNeeded}/>}
                {editNicknamePopNeeded && <EditNickNamePop setEditNicknamePopNeeded={setEditNicknamePopNeeded}
                                                           nickNameChangeId={nickNameChangeId}
                                                           setNickNameChangeId={setNickNameChangeId}/>}
            </Box>
        )
    }

}

function NewLooperPop(props) {
    const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true})
    let setNewLooperPopNeeded = props.setNewLooperPopNeeded
    const toast = useToast()
    let [newUserFormState, setNewUserFormState] = useState({nickname: []})
    let [newUserEmail, setNewUserEmail] = useState("")
    let [isLoading, setIsLoading] = useState(false)
    let session = useSessionContext();
    let {doesSessionExist, userId, accessTokenPayload} = session;
    const user = useContext(UserContext)
    const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN)

    const handleClose = () => {
        onClose()
        setNewLooperPopNeeded(false)
    };

    let handleSubmitTR = async (e) => {
        e.preventDefault()
        // console.log(newUserFormState.email)
        if (newUserEmail === "" || typeof newUserEmail === 'undefined') {
            toast({
                title: "Please enter an email",
                status: "error",
                isClosable: true
            })
            return
        }

        setIsLoading(true)
        let newArr = newUserFormState.nickname.filter(function(e){return e})
        setNewUserFormState({...newUserFormState, nickname: newArr})
        let obj = {
            supertokens_id: userId,
            email: newUserEmail,
            nickname: newUserFormState.nickname
        }
        // For some reason we have to do this twice.  I don't know.
        obj.nickname = obj.nickname.filter(function(e){return e})
        // console.log(obj)
        try {
            // console.log(obj)
            let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/add-to-loop", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            })
                .then(res => res.json())
                .then(resJSON => {
                    // if (res.status === 200) {
                        // let resJSON = res.json()
                        // console.log(resJSON)
                        if (resJSON.results === "no user found") {
                            toast({
                                title: "No user found with that email",
                                status: "info",
                                isClosable: true
                            })
                            setIsLoading(false)
                        } else if (resJSON.results === "not authorized") {
                            toast({
                                title: "You are not authorized to add this user",
                                status: "error",
                                isClosable: true
                            })
                            setIsLoading(false)
                        } else if (resJSON.results === "already connected") {
                            toast({
                                title: "You are already connected to this user",
                                status: "info",
                                isClosable: true
                            })
                            setIsLoading(false)
                        } else if (resJSON.results === "already requested") {
                            toast({
                                title: "You have a pending connection to this user",
                                status: "info",
                                isClosable: true
                            })
                            setIsLoading(false)
                        } else {
                            if (resJSON.results === "free user") {
                                toast({
                                    title: "Free Account User",
                                    description: newUserEmail + " does not have a pro plan. They need to upgrade to use this feature.",
                                    status: "info",
                                    duration: 5000,
                                    isClosable: true
                                })  
                            } else {
                                toast({
                                    title: "Success!",
                                    description: resJSON.results,
                                    status: "success",
                                    duration: 5000,
                                    isClosable: true
                                })
                            }
                            
                            setIsLoading(false)
                            setNewLooperPopNeeded(false)
                            user.setNeedsRefresh(true)
                        }
                })

        } catch (err){
            console.log(err)
            logtail.error(user.user_details.email + ": something went wrong adding a new user to loop", err)
            logtail.flush()
            toast({
                title: "Error adding data",
                status: "error",
                isClosable: true
            })
            setIsLoading(false)
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
                {/*<ModalHeader>Add New User</ModalHeader>*/}
                <ModalCloseButton />
                <ModalBody>
                    <Box>
                        <Center>
                            <Heading as={'h1'} fontSize={'2xl'}>Add To Your Loop</Heading>
                        </Center>
                        <Center p={3}>
                            <Text fontSize={'lg'} pb={2}>People in your loop can send you reminders (and vice versa).</Text>
                        </Center>
                        <Box p={3}>
                            <form onSubmit={handleSubmitTR}>
                                <FormControl>
                                    <FormLabel>Email of person to add to your loop:</FormLabel>
                                    {/*<Input type={'email'} name={'email'} onChange={(e) => setNewUserFormState({...newUserFormState, email: e.target.value})}/>*/}
                                    <Input type={'email'} name={'email'} onChange={(e) => setNewUserEmail(e.target.value)}/>
                                </FormControl>
                                <NicknameInput newUserFormState={newUserFormState} setNewUserFormState={setNewUserFormState}/>
                                <HStack justify={'right'} pt={1}>
                                    <Button color={'gray.600'} bg={'brand.pink'} isLoading={isLoading} type={'submit'}>Add to my loop</Button>
                                </HStack>
                            </form>
                        </Box>
                        <Center pt={3} pl={3} pr={3}>
                            <Text fontSize={'xl'} pb={5}>We'll reach out to your fellow looper to ask them to confirm your request and let you know what they say!</Text>
                        </Center>
                    </Box>


                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

function EditNickNamePop(props) {
    const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: true})
    const setEditNicknamePopNeeded = props.setEditNicknamePopNeeded
    const nickNameChangeId = props.nickNameChangeId
    const setNickNameChangeId = props.setNickNameChangeId
    const toast = useToast()
    let [newUserFormState, setNewUserFormState] = useState({nickname: []})
    let [isLoading, setIsLoading] = useState(false)
    let [editUserEmail, setEditUserEmail] = useState("")

    const user = useContext(UserContext)
    const user_details = user.user_details
    const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN)

    const handleClose = () => {
        onClose()
        // user.setNeedsRefresh(true)
        setEditNicknamePopNeeded(false)
        setNickNameChangeId(null)
        setEditUserEmail("")
    };

    useEffect(() => {
        // console.log("in edit nickname pop useeffect")
        user_details.sender.forEach((sender) => {
            if (sender.id === nickNameChangeId) {
                // console.log('found the user connection')
                setNewUserFormState({...newUserFormState, nickname: sender.receiver_name})
                setEditUserEmail(sender.receiver_email)
            }
        })
    }, [])

    let handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        let newArr = newUserFormState.nickname.filter(function(e){return e})
        setNewUserFormState({...newUserFormState, nickname: newArr})
        let obj = {
            // supertokens_id: userId,
            target_email: editUserEmail,
            name: newUserFormState.nickname
        }
        // For some reason we have to do this twice.  I don't know.
        obj.name = obj.name.filter(function(e){return e})
        // console.log(obj)
        try {
            // console.log(obj)
            let res = await fetch(process.env.REACT_APP_API_DOMAIN + "/nickname", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(obj)
            })
                .then(res => res.json())
                .then(resJSON => {
                    // if (res.status === 200) {
                        // let resJSON = res.json()
                        // console.log(resJSON)
                        if (resJSON.results === "no connection found") {
                            toast({
                                title: "You don't have a connection with this user",
                                status: "info",
                                isClosable: true
                            })
                            setIsLoading(false)
                        } else {
                            toast({
                                title: "Success!",
                                description: "You updated your nicknames for "+ editUserEmail,
                                status: "success",
                                isClosable: true
                            })
                            setIsLoading(false)
                            user.setNeedsRefresh(true)
                            handleClose()
                        }
                })

        } catch (err){
            console.log(err)
            logtail.error(user.user_details.email + ": something went wrong editing a nickname", err)
            logtail.flush()
            toast({
                title: "Error adding data",
                status: "error",
                isClosable: true
            })
            setIsLoading(false)
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
                {/*<ModalHeader>Add New User</ModalHeader>*/}
                <ModalCloseButton />
                <ModalBody>
                    <Box>
                        <Center>
                            <VStack>
                                <Heading as={'h1'} fontSize={'2xl'}>Edit Nicknames</Heading>
                                <Heading as={'h2'} fontSize={'lg'}>for {editUserEmail}</Heading>
                            </VStack>
                        </Center>
                        <Box p={3}>
                            <form onSubmit={null}>
                                <FormControl>
                                    {/*<FormLabel>Email of person to add to your loop:</FormLabel>*/}
                                    {/*<Input type={'email'} name={'email'} onChange={(e) => setNewUserEmail(e.target.value)}/>*/}
                                </FormControl>
                                <NicknameInput editMode={true} newUserFormState={newUserFormState} setNewUserFormState={setNewUserFormState}/>
                                <HStack justify={'right'} pt={1}>
                                    <Button color={'gray.600'} bg={'brand.pink'} isLoading={isLoading} onClick={handleSubmit}>Update Nicknames</Button>
                                </HStack>
                            </form>
                        </Box>
                    </Box>


                </ModalBody>
            </ModalContent>
        </Modal>
    )

}

export default RemindersForOthersDisplay;